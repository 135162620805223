import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Container, Row, Column } from '@common/Grid';
import { Text } from '@common/components/Text';
import Widget from './Widget';
import WidgetDefault from './WidgetDefault';

const Section = styled('div')`
  background-color: ${props => props.backgroundColor};
`;

const ColumnWrapIframe = styled(Column)`
  z-index: 0;
`;

const TextWidget = styled(Text)`
  border: 2px solid #fff;
`;

const WarningBg = styled('div')`
  background-color: #ffe74c;
  border: 2px solid #fff;
`;

const WarningLink = styled('a')`
  display: inline-block;
  color: #3772ff;
  text-decoration: none;
  box-shadow: inset 0 -1px 0px 0px currentColor;
  transition: box-shadow ease-out 0.2s;

  &:hover,
  &:focus {
    box-shadow: none;
  }
`;

const WidgetBlock = ({ block, ...props }) => {
  let widget = block?.widget ?? '';
  const backgroundColor = block?.backgroundColor ?? '#ffffff';
  const mode = props.mode ?? '';

  const isSynchCode = !!widget.match(/document.write/);
  widget = isSynchCode ? '' : widget;

  return (
    <Section backgroundColor={backgroundColor}>
      <Container py={[6, 8, 8, 10]} px={[1, 4, 2]}>
        <Row justifyContent="center">
          {widget.length > 0 ? (
            <>
              {mode !== 'public' ? (
                <Column width={1} px={[3, 1, 2]}>
                  <TextWidget
                    py={['119px', '62px']}
                    px={['22px', 0]}
                    bg="#7A8498"
                    fontFamily="Roboto,sans-serif"
                    fontSize={['16px', null, '20px', '24px']}
                    lineHeight="32px"
                    fontWeight="400"
                    color="#ECEBF3"
                    textAlign="center"
                  >
                    <FormattedMessage
                      id="block.installed"
                      defaultMessage="Виджет Рекламный блок Яндекса установлен"
                    />
                  </TextWidget>
                </Column>
              ) : (
                <ColumnWrapIframe
                  width={[1, null, 5 / 6, 2 / 3]}
                  px={[0, 1, 2]}
                >
                  <Widget {...props} widget={widget} />
                </ColumnWrapIframe>
              )}
            </>
          ) : (
            <Column width={1} px={[3, 1, 2]}>
              {isSynchCode ? (
                <WarningBg>
                  <Row justifyContent="center">
                    <Column width={5 / 6} py={['103px', '62px']}>
                      <Text
                        fontFamily="Roboto,sans-serif"
                        fontSize={['16px', null, null, '18px']}
                        lineHeight="32px"
                        fontWeight="400"
                        color="#13293D"
                      >
                        <FormattedMessage
                          id="block.warning.code"
                          defaultMessage="К сожалению, данный код не поддерживается сервисом."
                        />{' '}
                        <WarningLink
                          href="https://nethouse.ru/about/instructions/reklamnyi_blok_Yandex"
                          title="Как получить код виджета"
                          target="_blank"
                        >
                          <FormattedMessage
                            id="block.warning.link"
                            defaultMessage="Подробная инструкция"
                          />
                        </WarningLink>
                      </Text>
                    </Column>
                  </Row>
                </WarningBg>
              ) : (
                <WidgetDefault
                  href="https://nethouse.ru/about/instructions/reklamnyi_blok_Yandex"
                  title={
                    <FormattedMessage
                      id="block.default.name"
                      defaultMessage="Рекламный блок Яндекса"
                    />
                  }
                />
              )}
            </Column>
          )}
        </Row>
      </Container>
    </Section>
  );
};

WidgetBlock.propTypes = {
  block: PropTypes.shape({
    widget: PropTypes.string,
    backgroundColor: PropTypes.string
  })
};

export default WidgetBlock;

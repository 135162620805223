import React from 'react';
import PropTypes from 'prop-types';

import {
  FloatPanel,
  FrameEditor,
  FrameEditorContent
} from '@common/components/FloatPanel';
import WidgetBlock from './WidgetBlock';

const WidgetPanel = props => {
  const mode = props.rootContext?.mode;

  const blockProps = {
    block: props.props,
    blockId: props.rootContext?.blockId,
    entityId: props.fragmentEntity?.id,
    mode: mode
  };

  if (mode !== 'editor') {
    return <WidgetBlock {...blockProps} />;
  }

  return (
    <FrameEditor>
      <FloatPanel
        blockId={props.rootContext?.blockId}
        link={{
          editFragment: `/admin/fragment/${props.fragmentEntity?.id}`
        }}
        actions={['delete', 'editFragment']}
      />
      <FrameEditorContent>
        <WidgetBlock {...blockProps} />
      </FrameEditorContent>
    </FrameEditor>
  );
};

WidgetPanel.propTypes = {
  props: PropTypes.object,
  rootContext: PropTypes.shape({
    blockId: PropTypes.string.isRequired,
    // Мод в котором находится пользователь для отображения управления
    mode: PropTypes.oneOf(['public', 'editor', 'preview'])
  }),
  fragmentEntity: PropTypes.shape({
    id: PropTypes.string
  })
};

export default WidgetPanel;
